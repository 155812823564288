import React from "react";

import IndexLayout from "../layouts";
import styled from "@emotion/styled";

const StyledContactPage = styled.div`
  section {
    padding-top: 5rem;
  }

  p {
    &:last-of-type {
      margin-top: 2rem;
    }
  }

  .btn {
    margin-top: 4rem;
  }
`;

const ContactPage = () => (
  <IndexLayout canonical="/contact/" pageTitle="Contact">
    <StyledContactPage>
      <div className="row">
        <section className="wrapper">
          <h1 className="heading--underlined">Contact</h1>
          <p>
            Are you looking for a fast-performing and user-friendly website to
            represent your product or business? or looking for any kind of AI /
            ML projects? or want to ask questions? or have some advice for me or
            just want to say "Hi <span aria-hidden="true">👋</span>" in any case
            feel free to Let me know. I will do my best to respond back.{" "}
            <span aria-hidden="true">😊</span>
          </p>
          <p>Contact information</p>
          <p className="btn btn--dark">
            Sosnova 9, Ukrainka, Ukraine
          </p>
          <br></br>
          <a href="mailto:capobiancoshirelle@gmail.com" className="btn btn--dark">
            capobiancoshirelle@gmail.com
          </a>
          <br></br>
          <p className="btn btn--dark">
            +380 968528180
          </p>
        </section>
      </div>
    </StyledContactPage>
  </IndexLayout>
);

export default ContactPage;
